import Image from 'next/image';
import Link from 'next/link';

import { Typography, IconButton, Grid, List, ListItem, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 2, 6, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 0, 4, 0),
    },
    background: theme.palette.background.footer,
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    position: 'relative',
    width: 120,
    height: 32,
  },
  groupTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
  menuListContainer: {
    padding: '0 !important',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    margin: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
    color: 'white',
  },
  divider: {
    width: '100%',
  },
  navLink: {
    color: 'rgba(255,255,255,.7)',
  },
  awards: {
    marginTop: theme.spacing(1),
  },
  award: {
    position: 'relative',
    width: 80,
    height: 80,
  },
  copyright: {
    marginTop: theme.spacing(9),
    color: 'rgba(255,255,255,.5)',
  },
}));

const Footer = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const {
    // solutions,
    // platform,
    theCompany,
  } = pages;

  const MenuGroup = p => {
    const { item } = p;
    return (
      <List disablePadding className={classes.menuItem}>
        <ListItem disableGutters className={classes.menuGroupItem}>
          <Typography variant="body2" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          <ListItem disableGutters key={i.toString()} className={classes.menuGroupItem}>
            <Link href={page.href} passHref>
              <Typography
                variant="body2"
                component="a"
                className={clsx(classes.navLink, 'submenu-item')}>
                {page.title}
              </Typography>
            </Link>
          </ListItem>
        ))}
      </List>
    );
  };

  // const SolutionsPages = () => {
  //   const { institutions, sellers, extra, customers } = solutions.children;
  //   return (
  //     <div className={classes.menu}>
  //       <div>
  //         <MenuGroup item={institutions} />
  //         <MenuGroup item={sellers} />
  //         <MenuGroup item={customers} />
  //         <MenuGroup item={extra} />
  //       </div>
  //     </div>
  //   );
  // };

  // const PlatformPages = () => {
  //   const { onlineSales, customerSupport, payments, integrations, orders, management } =
  //     platform.children;
  //   return (
  //     <div className={classes.menu}>
  //       <div>
  //         <MenuGroup item={onlineSales} />
  //         <MenuGroup item={customerSupport} />
  //         <MenuGroup item={payments} />
  //       </div>
  //       <div>
  //         <MenuGroup item={integrations} />
  //         <MenuGroup item={orders} />
  //         <MenuGroup item={management} />
  //       </div>
  //     </div>
  //   );
  // };

  const TheCompanyPages = () => {
    const { eskolare, resources, integrations, legal } = theCompany.children;
    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={eskolare} />
        </div>
        <div>
          <MenuGroup item={resources} />
        </div>
        <div>
          <MenuGroup item={integrations} />
        </div>
        <div>
          <MenuGroup item={legal} />
        </div>
      </div>
    );
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container spacing={4} justifyContent="center" justifyItems="center">
          <Grid item xs={12} md={2}>
            <List disablePadding>
              <ListItem disableGutters className={classes.logoContainerItem}>
                <div className={classes.logoContainer}>
                  <Link href="/">
                    <a title="Eskolare">
                      <Image
                        className={classes.logoImage}
                        src="/logo/horizontal-white.svg"
                        alt="Eskolare"
                        layout="fill"
                        objectFit="contain"
                      />
                    </a>
                  </Link>
                </div>
              </ListItem>
              <ListItem disableGutters>
                <IconButton className={classes.socialIcon}>
                  <FacebookIcon className={classes.icon} />
                </IconButton>
                <IconButton className={classes.socialIcon}>
                  <InstagramIcon className={classes.icon} />
                </IconButton>
                <IconButton className={classes.socialIcon}>
                  <YouTubeIcon className={classes.icon} />
                </IconButton>
                <IconButton className={classes.socialIcon}>
                  <LinkedInIcon className={classes.icon} />
                </IconButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md="auto" className={classes.menuListContainer}>
            {/* <Grid container justifyContent="center" spacing={0}> */}
            {/* <Grid item>
                <SolutionsPages />
              </Grid> */}
            {/* <Grid item>
                <PlatformPages />
              </Grid> */}
            {/* <Grid item> */}
            <TheCompanyPages />
            {/* </Grid> */}
            {/* </Grid> */}
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center" className={classes.awards}>
          <Grid item xs={12}>
            <Typography variant="body2" align="center" className={classes.menuGroupTitle}>
              Prêmios e reconhecimentos
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Somos associados a ABO2O" aria-label="somos associados a abo2o">
              <a
                href="https://o2obrasil.com.br/associadoso2o/"
                target="_blank"
                rel="noopener noreferrer">
                <div className={classes.award}>
                  <Image
                    src="/images/awards/selo-abo2o.png"
                    alt="selo de associado da associação brasileira online to offline"
                    layout="fill"
                    objectFit="contain"
                    quality={60}
                  />
                </div>
              </a>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="AWS EdStart Member" aria-label="aws edstart member">
              <a
                href="https://aws.amazon.com/pt/education/edstart/members/"
                target="_blank"
                rel="noopener noreferrer">
                <div className={classes.award}>
                  <Image
                    src="/images/awards/selo-edstart.png"
                    alt="selo de membro da aws edstart"
                    layout="fill"
                    objectFit="contain"
                    quality={60}
                  />
                </div>
              </a>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Somos residentes do Cubo" aria-label="somos residentes do cubo">
              <a
                href="https://cubo.network/startups/eskolare"
                target="_blank"
                rel="noopener noreferrer">
                <div className={classes.award}>
                  <Image
                    src="/images/awards/cubo.png"
                    alt="selo de residente do cubo"
                    layout="fill"
                    objectFit="contain"
                    quality={60}
                  />
                </div>
              </a>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
      <div className={classes.copyright}>
        <Typography variant="body2" component="div" align="center" color="inherit">
          Copyright © 2021 Eskolare Negócios Digitais Ltda. Me - CNPJ: 28.180.616/0001-00
        </Typography>
        <Typography variant="body2" component="div" align="center" color="inherit">
          Endereço: Alameda Vicente Pínzon, 54 - Vila Olímpia, São Paulo/SP, CEP 04547-130
        </Typography>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
